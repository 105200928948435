.pictureCards {
  &-wrapper {
    width: 70%;
    padding-top: 16px;
  }

  &-imageBg {
    width: 90%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &-image {
    width: 100%;
  }

  &-contentCard {
    min-height: 240px;
    width: 110%;
    box-shadow: 0px 4px 40px rgba(220, 220, 220, 0.3);
    background-color: $white;
    border-radius: 20px;
    padding: 30px 80px;
    z-index: 10;
    position: relative;

    &-left {
      margin-left: -100px;
    }

    &-right {
      margin-left: 50px;
    }

    // @include respond(M) {
    //   padding: 15px 50px;
    //   min-height: 150px;
    //   width: 120%;
    // }

    @include respond(L) {
      width: 130%;
      min-height: 230px;
      padding: 15px 40px 0 40px;
    }

    @include respond(M) {
      min-height: 180px;
    }

    @include respond(S) {
      width: 100%;
      margin: 0;
      margin-top: 20px;
    }

  }

  &-nr {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: $medium;
    color: $black;
    margin-bottom: 10px;

    @include respond(M) {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  &-title {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: $semi-bold;
    color: $primary;
    margin-bottom: 10px;

    @include respond(M) {
      font-size: 20px;
      margin-bottom: 5px;
    }
  }
}
