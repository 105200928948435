@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@700&family=Inter:wght@400;500;600;700&display=swap');

.title {
  font-size: 20px;
  font-weight: $medium;
  text-transform: uppercase;
  color: $primary;

  @include respond(M) {
    font-size: 18px;
  }

  @include respond(S) {
    font-size: 14px;
  }
}

.title-card {
  font-size: 18px;
  font-weight: $medium;
  color: $primary;
}

.card-content {
  font-size: 14px;
  color: $black;
  line-height: 25px;

  @include respond(M) {
    font-size: 12px;
    line-height: 20px;
  }
}

.heading {
  color: $black;
  font-weight: $semi-bold;
  font-size: 45px;
  line-height: 55px;

  @include respond(M) {
    font-size: 38px;
    line-height: 50px;
  }

  @include respond(S) {
    font-size: 25px;
    line-height: 30px;
  }
}

.card-heading {
  color: $black;
  font-weight: $semi-bold;
  font-size: 35px;
  line-height: 45px;

  @include respond(M) {
    font-size: 25px;
    line-height: 35px;
  }

  @include respond(S) {
    font-size: 18px;
    line-height: 25px;
  }
}

.content-16 {
  font-size: 16px;
  color: $black;
  line-height: 28px;

  @include respond(M) {
    font-size: 14px;
    line-height: 20px;
  }

  @include respond(s) {
    font-size: 12px;
    line-height: 16px;
  }
}
