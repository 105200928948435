.popup {

  &-container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.3);
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-wrapper {
    width: 350px;
    min-height: 150px;
    border-radius: 8px;
    background-color: $primary;
    padding: 10px;
    border: 2px solid $white;
  }

  &-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  &-heading  {
    color: $white;
    text-transform: uppercase;
    text-align: center;
  }

  &-text {
    font-size: 14px;
    font-weight: $regular;
    color: $white;
    text-align: center;
  }
}