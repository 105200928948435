.types {
  margin-top: 150px;

  @include respond(S) {
    margin-top: 20px;
  }

  &-imageBg {
    position: absolute;
    right: 0;
    width: 80%;
    z-index: -1;
  }

  &-wrapper {
    margin-top: 50px;

    @include respond(S) {
      margin-top: 100px;
    }
  }

  &-people {
    padding: 20px;
    background: linear-gradient(
      149.51deg,
      rgba(234, 224, 255, 0.28) 0%,
      #f4f0ff 100%
    );
    border-radius: 20px;

    &-nr {
      font-size: 30px;
      font-weight: $semi-bold;
      color: $primary;
      margin-bottom: 10px;
    }
  }
}
