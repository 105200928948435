.hero {

  &-bg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;

    @include respond(XXL) {
      width: 80%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &-action {
    width: 50%;
    margin-top: 150px;
    margin-bottom: 400px;

    @include respond(L) {
      margin-bottom: 200px;
    }

    @include respond(M) {
      margin-top: 70px;
    }

    @include respond(S) {
      margin-bottom: 60px;
      margin-top: 0;
    }

    &-text {
      font-size: 70px;
      font-weight: $semi-bold;
      color: $black;
      line-height: 80px;

      @include respond(L) {
        font-size: 55px;
        line-height: 70px;
      }

      @include respond(M) {
        font-size: 40px;
        line-height: 50px;
      }

      @include respond(S) {
        font-size: 20px;
        line-height: 30px;
      }
    }

    &-span {
      color: $primaryLight;
    }
  }
}