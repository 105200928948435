.bonus {


  &-imageBg {
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  &-wrapper {
    width: 90%;
  }

  &-padding-top {
    padding-top: 200px;

    @include respond(S) {
      padding-top: 0;
    }
  }
}