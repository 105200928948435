.nav {
  padding: 20px 0;

  &-logo {
    font-size: 45px;
    font-weight: $bold;
    font-family: $logo-font;
    margin-bottom: 0;
  }

  &-button {
    padding: 10px 50px;
    background-color: $black;
    box-shadow: 0px 30px 30px rgba(148, 148, 148, 0.2);
    border-radius: 45px;

    &-text {
      margin-bottom: 0;
      text-transform: uppercase;
      color: $white;
      font-size: 14px;
    }
  }

  &-icon {
    color: $black;
    font-size: 30px;
  }
}
