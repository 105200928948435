.formSection {
  background-color: $primary;
  color: $white;

  &-heading {
    font-size: 25px;
    text-align: center;
    margin-bottom: 22px;

    @include respond(M) {
      font-size: 18px;
    }

    @include respond(S) {
      font-size: 16px;
    }
  }

  &-subHeading {
    font-size: 22px;
    text-align: center;

    @include respond(M) {
      font-size: 16px;
    }

    @include respond(S) {
      font-size: 14px;
    }
  }

  &-heading-small {
    font-size: 20px;

    @include respond(M) {
      font-size: 16px;
    }

    @include respond(S) {
      font-size: 14px;
    }
  }

  &-text-wrapper {
    border-left: 4px solid $green;
    padding-left: 10px;
  }

  &-text-content {
    font-size: 18px;

    @include respond(M) {
      font-size: 15px;
    }

    @include respond(S) {
      font-size: 12px;
    }
  }

  &-personalArea {
    &-heading {
      font-size: 24px;
      color: $black;
      margin-bottom: 8px;
    }

    &-subHeading {
      font-size: 20px;
      color: $black;
    }
  }

  &-input {
    background-color: #343737;
    border-radius: 5.33333px;
    width: 100%;
    height: 40px;
    padding: 5px 15px;
    color: #979797;
    outline: none;
    border: none;
    font-size: 14px;
  }

  &-input-radio {
    margin-right: 10px;

    &-label {
      display: inline;
    }
  }

  input[type="radio"] {
    accent-color: $primaryLight;
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }

  &-policy-text {
    font-size: 12px;
    text-decoration: underline;
    margin-bottom: 0;
  }

  input[type="checkbox"] {
    width: 25px;
    height: 25px;
    accent-color: $primaryLight;
  }

  &-button {
    margin-top: 100px;
    padding: 15px 30px;
    background: #27ae60;
    box-shadow: 0px 9.33333px 20px rgba(64, 19, 3, 0.1);
    border-radius: 26.6667px;
    text-align: center;
    cursor: pointer;

    &-text {
      margin-bottom: 0;
      text-transform: uppercase;
      font-size: 14px;
    }
  }
}
