.footer {
  background-color: $primary;
  padding: 30px 0;

  &-item {
    padding: 10px 50px;

    &-text {
      font-size: 12px;
      color: $white;
    }
  }
}