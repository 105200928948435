.card {
  border-radius: 20px;
  border: none;
  padding: 20px 30px;
  box-shadow: 0px 55.7411px 111.482px rgba(194, 183, 194, 0.3);

  &-nr {
    border-radius: 50%;
    background-color: $white;
    text-align: center;
    padding: 12px 0;
    width: 70px;
    height: 70px;
    margin-bottom: 10px;

    &-text {
      margin-bottom: 0;
      font-size: 30px;
      color: $primary;
      font-weight: $bold;
    }
  }
}