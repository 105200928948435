.faq {
  margin-top: 150px;

  @include respond(M) {
    margin-top: 50px;
  }

  @include respond(S) {
    margin-top: 20px;
  }

  &-image {
    width: 100%;

    &-wrapper {
      background: linear-gradient(
        97.56deg,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0.1) 100%
      );
      box-shadow: 0px 50px 100px rgba(154, 144, 154, 0.1);
      backdrop-filter: blur(10px);
      border-radius: 20px;
      padding: 20px;
      width: 80%;
      margin: 0 auto;
      margin-top: -30px;
    }
  }

  &-fitness {
    background: #ffd6fd;
    border-radius: 65px;
    padding: 10px 20px;

    &-text {
      margin-bottom: 0;
      font-size: 12px;
      font-weight: $medium;
      color: $black;
    }
  }

  &-by {
    font-weight: $regular;
    font-size: 16px;
    color: rgba(25, 23, 24, 0.5);
    margin-bottom: 0;
  }

  &-text {
    font-size: 18px;
    font-weight: $semi-bold;

    @include respond(M) {
      font-size: 14px;
    }

    @include respond(S) {
      font-size: 12px;
    }
  }
}

.accordion-item {
  border: none;
  border-bottom: 1px solid $gray;
  border-radius: 0;
}

.accordion-button:not(.collapsed) {
  background-color: $white;
}

.accordion-button:focus {
  outline: none !important;
  box-shadow: none;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion-item:last-of-type {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-button::after {
  background-image: url('../../assets/arrow-down.png');
  background-size: 40px;
  width: 40px;
  height: 40px;
}

.accordion-button:not(.collapsed)::after {
  background-image: url('../../assets/arrow-down.png');
}