.description {
  &-box {
    background: #ffffff;
    box-shadow: 0px 60px 60px rgba(221, 221, 221, 0.25);
    border-radius: 24px;
    text-align: center;
    padding: 10px 25px;

    &-gradient {
      padding: 1px;
      background: rgb(255, 72, 247);
      background: linear-gradient(
        150deg,
        rgba(255, 72, 247, 1) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 72, 247, 1) 100%
      );
      border-radius: 24px;
      margin-top: 10px;
    }

    &-heading {
      font-size: 30px;
      font-weight: $regular;
      color: $black;
      margin-bottom: 5px;
    }

    &-subHeading {
      font-size: 12px;
      font-weight: $regular;
      color: rgba(31, 31, 31, 0.6);
      margin-bottom: 0;
    }
  }

  &-image {
    width: 60%;
    position: absolute;
    right: 0;
    z-index: -1;
  }
}
