.privacy {

  &-wrapper {
    padding: 50px;

    @include respond(M) {
      padding: 35px;
    }

    @include respond(S) {
      padding: 20px;

    }
  }
}