
a {
  text-decoration: none;
}

.p-no-margin {
  margin-bottom: 0;
}

.color-primary {
  color: $primary;
}

.overlay-dark {
  background-color: rgba(0,0,0,0.8);
}